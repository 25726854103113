import React from 'react'
import BasePage from './BasePage'
import Typography from '@material-ui/core/Typography'

export default function BioinformaticsPage() {
    return (
        <BasePage>
            <Typography align="center">
                <h1>Coming soon</h1>
            </Typography>
        </BasePage>
    )
}
