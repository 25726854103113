export default `

<img src="portrait.jpg" height="200" width="200" />

I am Machine Learning enthusiast currently pursuing a
Master's Degree at the University of Oslo. I have a bachelors degree
in Computer Science (also from UiO) which I completed over 2 years.  


This page is dedicated to publishing projects as well a place for me to share
things I have recently learned!  
`
